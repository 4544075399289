<template>
  <div class="home">
    <child-page-head :title="title" />
    <div class="home-top-main" />
    <!-- 方块组件 -->
    <drawer-lifter>
      <!-- 左侧内容-上 -->
      <div slot="leftDrawerTopContent">
        <alarm-log-drawer-content type="LIFTER" :height="310" :row-num="5" />
      </div>
      <!-- 右侧内容-上 -->
      <div slot="rightDrawerTopContent">
        <alarm-line-drawer-content
          type="LIFTER"
          title="近15天人货梯预报警趋势"
          :top="15"
        />
      </div>
      <!-- 左侧内容-下 -->
      <div slot="draweBottomContent">
        <lifter-bottom-content />
      </div>
    </drawer-lifter>
  </div>
</template>
<script>
import childPageHead from '@/components/child-page-head'
import drawerLifter from '@/components/drawer-lifter.vue'
import alarmLogDrawerContent from '@/views/common/alarm-log-drawer-content.vue'
import alarmLineDrawerContent from '@/views/common/alarm-line-drawer-content.vue'
import lifterBottomContent from './components/lifter-bottom-content'
export default {
  name: 'LifterHome',
  components: {
    childPageHead,
    drawerLifter,
    alarmLogDrawerContent,
    alarmLineDrawerContent,
    lifterBottomContent
  },
  data() {
    return {
      title: '人货梯管理'
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  background: url(../../assets/image/body-bg.jpg) no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  zoom: 1;
  &-top-main {
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    height: "calc(100vh - 102px)";
  }
}
</style>
