<template>
  <div class="content-box-item-s">
    <el-tabs
      v-model="activeName"
      type="border-card"
      class="tabs-box"
      @tab-click="handleClick"
    >
      <template v-for="(item, index) in tabsList">
        <el-tab-pane :key="index" :label="item.name" :name="`${index}`">
          <el-row :gutter="20">
            <template v-if="item.latestDatas && item.latestDatas.length > 0">
              <template v-for="(late, li) in item.latestDatas">
                <el-col
                  :key="li"
                  :span="12"
                  :class="`${
                    item.latestDatas.length === 2 && li === 0
                      ? 'border-right'
                      : ''
                  }`"
                >
                  <div class="f">
                    <div class="p-r animated zoomIn">
                      <div class="f f-j-c mb-10 t-s">{{ li + 1 }}号笼</div>
                      <el-image
                        class="lifter-image-1"
                        :src="require('../../../assets/image/lifter-1.png')"
                      />
                      <el-image
                        class="lifter-image-2"
                        :src="require('../../../assets/image/lifter-2.png')"
                      />
                    </div>
                    <div class="lifter-details f f-c f-j-c">
                      <el-row :gutter="20">
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/lifter/rs.png')
                              "
                            />
                            <div class="li-text c-fff">人 数</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ late.peopleCount
                              }}<span class="sup-text">人</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/lifter/zl.png')
                              "
                            />
                            <div class="li-text c-fff">载 重</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ late.weight }}<span class="sup-text">kg</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/lifter/sd.png')
                              "
                            />
                            <div class="li-text c-fff">速 度</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ late.speed }}<span class="sup-text">m/s</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box mt-20 f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/lifter/gd.png')
                              "
                            />
                            <div class="li-text c-fff">高 度</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ late.height }}<span class="sup-text">m</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box mt-20 f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/lifter/lc.png')
                              "
                            />
                            <div class="li-text c-fff">楼 层</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ late.floor }}<span class="sup-text">层</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box mt-20 f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/lifter/qj.png')
                              "
                            />
                            <div class="li-text c-fff">倾 角</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ late.obliguityX
                              }}<span class="sup-text">度</span>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="8" class="animated zoomIn">
                          <div class="liter-box mt-20 f f-c f-j-c f-a-c">
                            <el-image
                              class="images-lifer"
                              :src="
                                require('../../../assets/image/lifter/fs.png')
                              "
                            />
                            <div class="li-text c-fff">风 速</div>
                            <div class="li-text mt-10 c-35FF69">
                              {{ late.windSpeed
                              }}<span class="sup-text">m/s</span>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div></el-col>
              </template>
            </template>
            <template v-else>
              <div class="not-data f f-j-c f-a-c">暂无“{{item.name}}”人货梯相关数据...</div>
            </template>
          </el-row>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import { getLiferData } from '@/api/device'
export default {
  data() {
    return {
      activeName: '0',
      tabsList: []
    }
  },
  created() {
    this.getlifterList()
  },
  methods: {
    /**
     * 获取全部人货梯数据
     */
    getlifterList() {
      getLiferData().then((res) => {
        const { data } = res
        this.$nextTick(() => {
          this.tabsList = data.items && data.items.length > 0 ? data.items : []
        })
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #fff;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: rgba(0, 255, 255, 1) !important;
  border: 1px solid rgba(0, 255, 255, 0.6);
  background: rgba(0, 255, 255, 0.2);
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.5) 1%,
    rgba(0, 255, 255, 0.2) 50%,
    rgba(0, 255, 255, 0) 50%
  );
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: rgba(0, 255, 255, 1) !important;
}
::v-deep .el-tabs__nav-scroll {
  background: rgba(15, 80, 133, 1);
  padding: 5px;
}
.content-box-item-s {
  padding: 4px;
}
.tabs-box {
  width: 100%;
  height: 545px;
  background: rgba(15, 80, 133, 0.8);
  border: none;
}
.t-s {
  font-family: "微软雅黑";
  font-size: 16px;
  color: #fff !important;
}
.t-s-g {
  font-family: "微软雅黑";
  font-size: 16px;
  color: #c7c9d4 !important;
}
.t-s-t {
  font-family: "微软雅黑";
  font-size: 14px;
  color: #fff !important;
}
.main-w {
  width: 330px;
}
.lifter-image-1 {
  width: 201px;
  height: 435px;
}
.lifter-image-2 {
  width: 162px;
  height: 160px;
  position: absolute;
  bottom: 10px;
  right: 19px;
}
.lifter-avter {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.lifter-details {
  width: 100%;
  padding: 10px;
  background: rgba(153, 153, 153, 0.05);
  margin-left: 10px;
  .liter-box {
    border: 1px solid #1d758f;
    background: rgba(11, 38, 92, 0.04);
    box-shadow: 0 0 30px #1c5e77 inset;
    padding: 5px;
  }
  .images-lifer {
    width: 50px;
    height: 50px;
  }
  .li-text {
    font-size: 20px;
    font-family: "微软雅黑";
    font-weight: bold;
  }
  .sup-text {
    font-size: 10px !important;
    font-family: "微软雅黑";
    color: #999e88;
    margin-left: 5px;
  }
}
.border-right {
  border-right: 1px solid #999 !important;
}
.not-data {
  width: 100%;
  height: 435px;
  font-size: 30px !important;
  font-family: "微软雅黑";
  color: #999e88;
}
</style>
