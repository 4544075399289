<template>
  <div class="device-alarm-stat-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div v-if="hasVal(alarmTrendOptions)" class="stat-line-context">
      <line-chart
        id="device-line-alarm-trend"
        :val-options="alarmTrendOptions"
        title=""
        unit="次"
        :height="lineHeight"
        y-axis-title="报警次数(频次/天)"
        serie-name="频次"
      />
    </div>
    <div v-else class="stat-charts-context empty">暂无{{ title }}</div>
  </div>
</template>
<script>
import { getDeviceAlarmLineStat } from '@/api/device'
import lineChart from '@/components/chart/lineChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'DeviceAlarmTrendStat',
  components: { lineChart },
  props: {
    title: {
      type: String,
      default: '近7天设备预报警趋势'
    },
    type: {
      type: String,
      default: 'ENVSENSOR'
    },
    top: {
      type: Number,
      default: 7
    },
    lineHeight: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      alarmTrendOptions: []
    }
  },
  mounted() {
    this.removeAlarmTrendStat()
  },
  methods: {
    removeAlarmTrendStat() {
      this.alarmTrendOptions = []
      getDeviceAlarmLineStat(this.type, this.top).then((res) => {
        const { data } = res
        if (hasVal(data.items)) {
          let _data = []
          let _name = ''
          data.items.forEach((item) => {
            _data = []
            for (const prop in item) {
              if (prop === 'Name') {
                _name = item[prop]
                delete item.Name
                break
              }
            }
            this.alarmTrendOptions.push({
              name: _name,
              data: item,
              isAllData: true
            })
          })
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.device-alarm-stat-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-line-context {
    width: 99%;
    padding-left: 0.5%;
    margin-top: 10px;
  }
}
</style>
